export interface IUserLogin {
  username: string;
  password: string;
}

export interface IUser {
  id: number;
  username: string;
  roles: Role[];
}

export interface IOperator extends IUser {
  password: string
  name: string
  telegram: string
}

export enum Role {
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR"
}
