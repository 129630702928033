import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSliceType } from "./types";
import { IUser } from "../../models/IUser";

const initialState: UserSliceType = {
  isAuth: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<IUser>) {
      state.user = payload;
      state.isAuth = true;
    },
    logout(state) {
      state.user = undefined;
      state.isAuth = false;
    },
  },
});

export const { setUser, logout } = userSlice.actions;

export default userSlice.reducer;
