import { FC, useState } from "react";
import { IOperator } from "../models/IUser";
import { generatePassword, getOperatorUsername } from "../utils";
import AuthService from "../services/auth.service";
import {
  Button, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputRightElement, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tooltip,
  useDisclosure, useToast,
} from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

type Props = {
  onSuccess: (user: IOperator) => void
  operators: IOperator[]
}

type OperatorForm = {
  name: string
  telegram: string
}

const AddOperator: FC<Props> = ({ onSuccess, operators }) => {
  const { handleSubmit, control, reset, formState: { errors } } = useForm<OperatorForm>({
    defaultValues: {
      name: "",
      telegram: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const closeModalAddHandler = () => {
    onClose();
    reset();
  };

  const onSubmit:SubmitHandler<OperatorForm> = (data) => {
    setIsLoading(true);
    const username = getOperatorUsername(operators);
    const password = generatePassword(10);
    AuthService.createOperator(username, password, data.name, data.telegram).then((oper) => {
      onSuccess(oper);
      toast({
        status: "success",
        description: `${username} успешно добавлен`,
      });
      closeModalAddHandler()
    }).catch(() => {
      toast({
        status: "error",
        title: "Ошибка",
        description: "Не удалось создать оператора",
      });
    }).finally(() => setIsLoading(false));
  };
  return (
    <>
      <Tooltip label="Добавить нового оператора">
        <Button isLoading={isLoading} onClick={onOpen}>Добавить</Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={closeModalAddHandler} returnFocusOnClose={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader className="!text-2xl">Добавление оператора</ModalHeader>
          <ModalCloseButton/>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Controller name="name" rules={{
                required: "Это обязательное поле",
                minLength: { value: 1, message: "Минимальная длина 1 символ" },
              }} control={control} render={({ field }) => <FormControl isInvalid={!!errors.name}>
                <FormLabel className="!text-xl">Имя, фамилия</FormLabel>
                <InputGroup>
                  <Input
                    h={50}
                    focusBorderColor="teal.200"
                    className="!text-xl"
                    type="text"
                    placeholder="Введите имя и фамилию"
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <InputRightElement className="flex items-center justify-center !h-full">
                    <Icon viewBox="0 0 24 24" className="!w-6 !h-6 fill-white">
                      <path
                        d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"/>
                    </Icon>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>}/>
              <Controller name="telegram" rules={{
                required: "Это обязательное поле",
                minLength: { value: 1, message: "Минимальная длина 1 символ" },
              }} control={control} render={({ field }) => <FormControl isInvalid={!!errors.telegram} className="mt-4">
                <FormLabel className="!text-xl">Telegram</FormLabel>
                <InputGroup>
                  <Input
                    h={50}
                    focusBorderColor="teal.200"
                    className="!text-xl"
                    type="text"
                    placeholder="Введите telegram"
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <InputRightElement className="flex items-center justify-center !h-full">
                    <Icon viewBox="0 0 24 24" className="!w-6 !h-6 fill-white">
                      <path
                        d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"/>
                    </Icon>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.telegram?.message}</FormErrorMessage>
              </FormControl>}/>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeModalAddHandler} mr={3} variant="ghost">Отмена</Button>
              <Button type="submit" isLoading={isLoading}>Добавить</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddOperator;
