import { FC, useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    Tooltip,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";

import { AddIcon, InfoIcon } from "@chakra-ui/icons";
import { MessageService } from "../services/message.service";
import { FileService } from "../services/file.service";
import { IFile, IMessage } from "../models/IMessage";
import { distributePackCounts } from "../utils";
import FileList from "./FileList";

type Props = {
    onSuccess: (data: IMessage) => void
    modelId: number
    modelPackCount: number
    defaultValue?: IMessage
    videos: IFile[]
    photos: IFile[]
    onDeleteFile: (fileId: number) => void
}

const AddMessage: FC<Props> = ({
                                   onSuccess,
                                   modelId,
                                   modelPackCount,
                                   defaultValue,
                                   videos: videosInit,
                                   photos: photosInit,
                                   onDeleteFile,
                               }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    const closeModalAddHandler = () => {
        onClose();
        setVideos(null);
        setPhotos(null);
        setIsLoading(false);
        // setMessage("");
    };

    const [message, setMessage] = useState("");
    const [photos, setPhotos] = useState<FileList | null>(null);
    const [videos, setVideos] = useState<FileList | null>(null);
    const toast = useToast();

    useEffect(() => {
        if (defaultValue)
            setMessage(defaultValue.messages.map(item => item.text).join("\n"));
    }, [defaultValue]);

    const onSubmit = async () => {
        const texts = message.trim().split("\n").filter(item => item.trim().length);
        if (texts.length === 0) {
            toast({
                status: "error",
                title: "Ошибка",
                description: "Кол-во сообщений должно быть больше 0",
            });
            return;
        }
        if (texts.some(item => item.length < 4)) {
            toast({
                status: "error",
                title: "Ошибка",
                description: "Минимальная длина каждого сообщения должна быть 3 символа",
            });
            return;
        }
        if (texts.length > modelPackCount) {
            toast({
                status: "error",
                title: "Ошибка",
                description: "Кол-во сообщений не может быть больше размера группы",
            });
            return;
        }

        setIsLoading(true);


        let photosUrls: string[] = [], videosUrls: string[] = [];
        try {
            if (photos && photos?.length > 0) {
                photosUrls = (await FileService.upload(photos, "models")).map(item => item.url);
            }

            if (videos && videos?.length > 0) {
                videosUrls = (await FileService.upload(videos, "models")).map(item => item.url);
            }

            const res = await MessageService.add({
                texts: distributePackCounts(texts, modelPackCount),
                photos: photosUrls,
                videos: videosUrls,
                modelId
            });
            onSuccess(res);
            closeModalAddHandler();
        } catch (e) {
            toast({
                status: "error",
                title: "Ошибка",
                description: "Произошла ошибка при добавлении новых сообщений",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const messagesCount = message.trim().length === 0 ? 0 : message.trim().split("\n")
        .filter(item => item.trim().length).length;

    return (
        <>
            <Tooltip label="Добавить сообщения для рассылки">
                <IconButton aria-label="Добавить сообщения для рассылки" onClick={onOpen} size="sm"
                            isLoading={isLoading}><AddIcon/></IconButton>
            </Tooltip>
            <Modal isOpen={isOpen} onClose={closeModalAddHandler} returnFocusOnClose={false}>
                <ModalOverlay/>
                <ModalContent className="min-w-[700px]">
                    <ModalHeader className="!text-2xl">Добавление сообщений</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl>
                            <FormLabel className="!text-xl !flex !items-center justify-between">
                                <Box className="flex items-center gap-2">
                                    <Text>Сообщения</Text>
                                    <Tooltip label="Список сообщений. Разделитель - Enter">
                                        <InfoIcon/>
                                    </Tooltip>
                                </Box>
                                <Text>Кол-во сообщений: {messagesCount}</Text>
                            </FormLabel>
                            <Textarea
                                minH={200}
                                focusBorderColor="teal.200"
                                className="!text-xl"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </FormControl>
                        <Box className="flex items-center justify-between">
                            <FormControl className="mt-4">
                                <FileList btnText="Прикрепить фото" count={photos?.length ?? photosInit.length} files={photosInit} htmlFor="photos"
                                          onDelete={(fileId) => onDeleteFile(fileId)}/>
                                <Input
                                    id="photos"
                                    name="photos"
                                    hidden
                                    accept="image/*"
                                    className="!text-xl"
                                    type="file"
                                    multiple
                                    focusBorderColor="teal.200"
                                    onChange={(e) => {
                                        setPhotos(e.target.files);
                                    }}
                                />
                            </FormControl>
                            <FormControl className="mt-4 flex justify-end">
                                <FileList btnText="Прикрепить видео" count={videos?.length ?? videosInit.length} files={videosInit} htmlFor="videos"
                                          onDelete={(fileId) => onDeleteFile(fileId)}/>
                                <Input
                                    hidden
                                    id="videos"
                                    name="videos"
                                    className="!text-xl"
                                    type="file"
                                    accept="video/mp4,video/x-m4v,video/*"
                                    focusBorderColor="teal.200"
                                    onChange={(e) => {
                                        setVideos(e.target.files);
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={closeModalAddHandler} mr={3} variant="ghost">Отмена</Button>
                        <Button onClick={onSubmit} isLoading={isLoading}>Сохранить</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddMessage;
