import * as userActions from "./user/slice";
import * as settingsActions from "./settings/slice";
import * as adminsActions from "./admin/slice";

export const allActions = {
  ...userActions,
  ...settingsActions,
  ...adminsActions
};

