import * as React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
    TableContainer,
    Flex,
    Tooltip,
    Text,
    IconButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Select, Spinner,
} from '@chakra-ui/react';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
} from '@chakra-ui/icons';
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel, RowSelectionState, PaginationState,
} from '@tanstack/react-table';
import {useCallback} from 'react';
import cn from 'classnames';

export type DataTableProps<Data extends Record<string, any>> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
    rowCount?: number,
    onPageChange: (pagination: PaginationState) => void
    isLoading?: boolean
    rowSelection?: RowSelectionState
    setRowSelection?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
    rowId?: keyof Data
    defaultSorting?: SortingState
};

export function DataTable<Data extends Record<string, any>>({
                                                                data,
                                                                columns,
                                                                rowCount = 0,
                                                                onPageChange,
                                                                isLoading = false,
                                                                setRowSelection,
                                                                rowSelection = {},
                                                                rowId = 'id',
                                                                defaultSorting = [],
                                                            }: DataTableProps<Data>) {
    const onPaginationChange = useCallback((pagination: PaginationState) => {
        setPagination(pagination);
        onPageChange(pagination);
    }, [onPageChange]);

    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });

    const [sorting, setSorting] = React.useState<SortingState>(defaultSorting);
    const table = useReactTable({
        columns,
        data,
        rowCount,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange: setRowSelection,
        getRowId: row => (row[rowId] as string).toString(),
        onPaginationChange: updaterOrValue => {
            setPagination(updaterOrValue);
            const value = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;
            onPaginationChange(value);
        },
        manualPagination: true,
        state: {
            sorting,
            rowSelection,
            pagination,
        },
    });

    return (
        <TableContainer>
            {isLoading ? <div className="flex items-center justify-center min-h-[calc(100vh_-_18.75rem)]">
                    <Spinner size="xl" color="teal.400" speed="0.7s" thickness="2px"/>
                </div> :
                <Table variant="striped">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => <Th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    className={cn({
                                        'cursor-pointer select-none w-fit': header.column.getCanSort(),
                                    })}
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}

                                    <chakra.span pl="2">
                                        {header.column.getIsSorted() ? (
                                            header.column.getIsSorted() === 'desc' ? (
                                                <ChevronDownIcon aria-label="sorted descending"/>
                                            ) : (
                                                <ChevronUpIcon aria-label="sorted ascending"/>
                                            )
                                        ) : null}
                                    </chakra.span>
                                </Th>)}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                                const type = 'type' in row.original && row.original.type && row.original.type === 'PENDING_CUSTOM_VID';
                                return (
                                    <Td key={cell.id} className={cn({
                                        '!bg-red-400': type,
                                        // @ts-ignore
                                        '!w-5 !p-0 !text-center': cell.column.columnDef.accessorKey === 'isExternal'
                                    })}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                );
                            })}
                        </Tr>)}
                    </Tbody>
                </Table>}
            {rowCount > 0 && <Flex justifyContent="space-between" m={4} alignItems="center">
                <Flex>
                    <Tooltip label="Первая страница">
                        <IconButton
                            onClick={() => table.firstPage()}
                            isDisabled={!table.getCanPreviousPage()}
                            icon={<ArrowLeftIcon h={3} w={3}/>}
                            mr={4}
                            aria-label="Первая страница"/>
                    </Tooltip>
                    <Tooltip label="Предыдущая страница">
                        <IconButton
                            onClick={() => table.previousPage()}
                            isDisabled={!table.getCanPreviousPage()}
                            icon={<ChevronLeftIcon h={6} w={6}/>} aria-label="Предыдущая страница"/>
                    </Tooltip>
                </Flex>

                <Flex alignItems="center">
                    <Text flexShrink="0" mr={8}>
                        Страница{' '}
                        <Text fontWeight="bold" as="span">
                            {table.getState().pagination.pageIndex + 1}
                        </Text>{' '}
                        из{' '}
                        <Text fontWeight="bold" as="span">
                            {table.getPageCount().toLocaleString()}
                        </Text>
                    </Text>
                    <Text flexShrink="0">Перейти к:</Text>{' '}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={28}
                        min={1}
                        max={table.getPageCount()}
                        onChange={(value) => {
                            const page = value ? Number(value) - 1 : 0;
                            table.setPageIndex(page);
                        }}
                        defaultValue={table.getState().pagination.pageIndex + 1}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <Select
                        w={40}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Показать {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>

                <Flex>
                    <Tooltip label="Следующая страница">
                        <IconButton
                            onClick={() => table.nextPage()}
                            isDisabled={!table.getCanNextPage()}
                            icon={<ChevronRightIcon h={6} w={6}/>}
                            aria-label="Следующая страница"/>
                    </Tooltip>
                    <Tooltip label="Последняя страница">
                        <IconButton
                            onClick={() => table.lastPage()}
                            isDisabled={!table.getCanNextPage()}
                            icon={<ArrowRightIcon h={3} w={3}/>}
                            ml={4}
                            aria-label="Последняя страница"/>
                    </Tooltip>
                </Flex>
            </Flex>}
        </TableContainer>
    );
}
