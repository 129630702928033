import { FC } from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NotFound: FC = () => {
  return (
    <Box className="flex flex-col items-center justify-center w-full h-screen">
      <Heading>404 Страница не найдена</Heading>
      <Link to="/" className="mt-4">
        <Button className="!text-xl">На главную</Button>
      </Link>
    </Box>
  );
};

export default NotFound;
