import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    isPaused: false,
    maxActiveModels: 0,
    activeModels: 0
  },
  reducers: {
    setSettings(state, payload:PayloadAction<{isPaused: boolean, maxActiveModels: number, activeModels: number}>) {
      state.isPaused = payload.payload.isPaused
      state.maxActiveModels = payload.payload.maxActiveModels
      state.activeModels = payload.payload.activeModels
    }
  }
})

export const {setSettings} = settingsSlice.actions

export default settingsSlice.reducer
