import axios from "../axios";
import { getFileUrl } from "../config/api.config";

export const FileService = {
    async upload(files: FileList, folder?: string) {
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append(`file`, files[i])
        }

        const { data } = await axios.post<{
            url: string,
            name: string
        }[]>(getFileUrl(folder ?? ''), formData, { headers: { "Content-Type": "multipart/form-data" } })
        return data
    },
    async remove(id: number) {
        const { status } = await axios.delete(`/upload/${id}`)
        return status === 200
    }
}
