import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { API_URL, getAuthUrl } from "./config/api.config";
import AuthService, { LoginData } from "./services/auth.service";

export const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "true"
  },
});

instance.interceptors.request.use((config) => {
  const token = AuthService.getAccessToken();

  if (config.headers && token)
    config.headers.Authorization = `Bearer ${token}`;

  return config;
});

instance.interceptors.response.use(config => config, async (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig & { _isRetry: boolean };
  // @ts-ignore
  if (error.response?.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const response = await axios.post<LoginData>(`${API_URL}${getAuthUrl("/refresh")}`, undefined, {
        headers: {
          Authorization: `Refresh ${AuthService.getRefreshToken()}`,
        },
      });
      AuthService.setTokens(response.data.backendTokens.accessToken, response.data.backendTokens.refreshToken);
      // localStorage.setItem('token', response.data.backendTokens.accessToken);
      return instance.request(originalRequest!);
    } catch (e) {
      AuthService.removeTokens();
      throw e;
    }
  }
  throw error;
});

export default instance;

