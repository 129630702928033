import axios from "../axios";
import { getAuthUrl } from "../config/api.config";
import { IOperator, IUser, IUserLogin } from "../models/IUser";

export type LoginData = { user: IUser, backendTokens: { refreshToken: string, accessToken: string } }

const AuthService = {
  async login(dto: IUserLogin) {
    const { data } = await axios.post<LoginData>(getAuthUrl("/login"), dto);
    return data;
  },
  getAccessToken() {
    const token = localStorage.getItem("vids-access-token");
    return token ?? "";
  },
  getRefreshToken() {
    const token = localStorage.getItem("vids-refresh-token");
    return token ?? "";
  },
  removeTokens() {
    localStorage.removeItem("vids-refresh-token");
    localStorage.removeItem("vids-access-token");
  },
  setTokens(accessToken: string, refreshToken: string) {
    if (accessToken && refreshToken) {
      localStorage.setItem("vids-access-token", accessToken);
      localStorage.setItem("vids-refresh-token", refreshToken);
    }
  },
  async getMe() {
    const { data } = await axios.get<IUser>(getAuthUrl("/me"));
    return data;
  },

  async getOperators(){
    const { data } = await axios.get<IOperator[]>(getAuthUrl("/operators"));
    return data;
  },
  async createOperator(username: string, password: string, name: string, telegram: string) {
    const { data } = await axios.post<IOperator>(getAuthUrl("/register"), {username, password, name, telegram, role: "OPERATOR"});
    return data;
  },
  async deleteOperator(id: number) {
    const { status } = await axios.delete<IOperator>(getAuthUrl(`/operators/${id}`));
    return status === 200;
  },
};

export default AuthService;
