import { FC, useEffect, useState } from "react";
import {
  Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, ButtonGroup, FormControl, FormErrorMessage, FormLabel,
  Heading, Icon, Input, InputGroup, InputRightElement, useColorMode, useToast,
} from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LockIcon } from "@chakra-ui/icons";
import AuthService from "../services/auth.service";
import { useActions } from "../store/useActions";
import { AxiosError } from "axios";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { Role } from "../models/IUser";

type LoginForm = {
  username: string
  password: string
}

const Login: FC = () => {
  const { handleSubmit, control, formState: { errors } } = useForm<LoginForm>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const { setUser } = useActions();
  const {setColorMode} = useColorMode()
  const navigate = useNavigate();
  const toast = useToast()

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    if (toast.isActive("login"))
      return
    try {
      setIsLoading(true);
      const res = await AuthService.login(data);
      if (res) {
        setIsInvalid(false);
        if (!res.user.roles.includes(Role.ADMIN)) {
          toast({
            status: "error",
            title: "Ошибка",
            description: "Доступ запрещен",
            id: "login"
          })
          return
        }
        setUser(res.user);
        AuthService.setTokens(res.backendTokens.accessToken, res.backendTokens.refreshToken);
        navigate("/");
      }

    } catch (e) {
      const err = e as AxiosError;
      if (err.response?.status === 401)
        setIsInvalid(true);
      else
        console.log(e);
    }
    finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    setColorMode('dark')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className="flex items-center justify-center h-screen">
      <Box className="flex flex-col border p-8 justify-center items-center rounded-xl w-[500px]">
        <Heading>Авторизация</Heading>
        {isInvalid && <Alert status="error" className="my-5">
            <AlertIcon/>
            <AlertTitle>Ошибка</AlertTitle>
            <AlertDescription>
                Неверный логин или пароль
            </AlertDescription>
        </Alert>}
        <form onSubmit={handleSubmit(onSubmit)} className={cn("mb-10", "w-full", { "mt-10": !isInvalid })}>
          <Controller name="username" rules={{
            required: "Это обязательное поле",
            minLength: { value: 3, message: "Минимальная длина 3 символа" },
          }} control={control} render={({ field }) => <FormControl isInvalid={!!errors.username}>
            <FormLabel className="!text-xl">Username</FormLabel>
            <InputGroup>
              <Input
                h={50}
                focusBorderColor="teal.200"
                className="!text-xl"
                type="text"
                placeholder="Введите имя пользователя"
                value={field.value}
                onChange={field.onChange}
              />
              <InputRightElement className="flex items-center justify-center !h-full">
                <Icon viewBox="0 0 24 24" className="!w-6 !h-6 fill-white">
                  <path
                    d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"/>
                </Icon>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
          </FormControl>}/>
          <Controller name="password" rules={{
            required: "Это обязательное поле",
            minLength: { value: 6, message: "Минимальная длина 6 символов" },
          }} control={control} render={({ field }) => <FormControl isInvalid={!!errors.password} className="mt-4">
            <FormLabel className="!text-xl">Пароль</FormLabel>
            <InputGroup>
              <Input
                h={50}
                className="!text-xl"
                type="password"
                focusBorderColor="teal.200"
                placeholder="**************"
                value={field.value}
                onChange={field.onChange}
              />
              <InputRightElement className="flex items-center justify-center !h-full">
                <LockIcon className="!w-6 !h-6"/>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>}/>
          <ButtonGroup className="flex mt-8 items-center justify-center w-full">
            <Button size="lg" type="submit" isLoading={isLoading}>Войти</Button>
          </ButtonGroup>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
