import { FC, useRef } from 'react';
import { IFile } from "../models/IMessage";
import {
    Button,
    FormLabel,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tag,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { FileService } from "../services/file.service";

type Props = {
    btnText: string
    files: IFile[]
    count: number
    onDelete: (fileId: number) => void
    htmlFor: string
}

const FileList: FC<Props> = ({ files, btnText, onDelete, htmlFor, count }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const btnRef = useRef<HTMLButtonElement>(null)
    // const [renderFiles, setRenderFiles] = useState<IFile[]>([])

    const removeHandler = () => {
        for (let i = 0; i < files.length; i++) {
            FileService.remove(files[i].id)
                .then(res => {
                    if (!res)
                        toast({
                            status: "error",
                            title: "Ошибка",
                            description: "Произошла ошибка при удалении файла",
                            id: "file-id"
                        })
                    else
                        onDelete(files[i].id)
                })
                .catch(() => {
                    toast({
                        status: "error",
                        title: "Ошибка",
                        description: "Произошла ошибка при удалении файла",
                        id: "file-id"
                    })
                })
        }
    }

    // useEffect(() => {
    //     const tmp = files
    //     if (fileList) {
    //         for (let i = 0; i < fileList.length; i++) {
    //             tmp.push({id: -Date.now() + i, type, url: URL.createObjectURL(fileList[i])})
    //         }
    //     }
    //     setRenderFiles(tmp)
    // }, [fileList, files, type]);

    if (!files.length)
        return (
            <FormLabel className="!m-0 w-fit" htmlFor={htmlFor}>
                <Button as="span" gap={2} alignItems="center" ref={btnRef}>
                    <Text>{btnText}</Text>
                    <Tag colorScheme="black" rounded="full" variant="solid" alignItems="center"
                         justifyContent="center">{count}</Tag>
                </Button>
            </FormLabel>
        )

    return (
        <>
            <FormLabel className="!hidden" htmlFor={htmlFor}>
                <Button as="span" gap={2} alignItems="center" ref={btnRef}>
                    <Text>{btnText}</Text>
                    <Tag colorScheme="black" rounded="full" variant="solid" alignItems="center"
                         justifyContent="center">{count}</Tag>
                </Button>
            </FormLabel>
            <Popover placement="top" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <PopoverTrigger>
                    <Button as="span" gap={2} alignItems="center">
                        <Text>{btnText}</Text>
                        <Tag colorScheme="black" rounded="full" variant="solid" alignItems="center"
                             justifyContent="center">{count}</Tag>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="max-w-[100px]">
                    {/*<PopoverHeader>Файлы</PopoverHeader>*/}
                    {/*<PopoverBody>*/}
                    {/*    <UnorderedList pb={2}>*/}
                    {/*        {renderFiles.map(file => <ListItem key={file.id}>*/}
                    {/*            <Box className="flex items-center justify-between">*/}
                    {/*                <ImagePreview file={file}/>*/}
                    {/*                <DeleteIcon className="cursor-pointer [&>g]:transition-colors hover:[&>g]:!fill-teal-400"*/}
                    {/*                            onClick={() => removeHandler(file.id)}/>*/}
                    {/*            </Box>*/}
                    {/*        </ListItem>)}*/}
                    {/*    </UnorderedList>*/}
                    {/*</PopoverBody>*/}
                    {files.length &&
                        <Button onClick={removeHandler} colorScheme="red" variant="outline">Очистить</Button>}
                </PopoverContent>
            </Popover>
        </>
    );
};

export default FileList;
