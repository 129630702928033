import { FC, useEffect, useState } from "react";
import { Box, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react";
import { IOperator } from "../models/IUser";
import AuthService from "../services/auth.service";
import AddOperator from "../components/AddOperator";
import { CopyIcon } from "@chakra-ui/icons";
import { copyTextToClipboard } from "../utils";
import DeleteOperator from "../components/DeleteOperator";

const Operators: FC = () => {
  const [operators, setOperators] = useState<IOperator[]>([]);
  const toast = useToast();

  useEffect(() => {
    AuthService.getOperators().then(setOperators).catch((err) => {
      if (err.response.status === 403 || err.response.status === 401) {
        toast({
          status: "error",
          title: "Ошибка",
          description: "Доступ запрещен",
        });
      }
    });
  }, [toast]);

  const copyHandler = async (username: string, password: string) => {
    await copyTextToClipboard(`Логин: ${username}\nПароль: ${password}`);
    toast({
      status: "info",
      description: "Текст скопирован в буфер обмена",
    });
  };


  return (
    <Box>
      <Box className="flex items-center justify-end">
        <AddOperator onSuccess={(oper) => setOperators(prevState => ([oper, ...prevState]))} operators={operators}/>
      </Box>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Имя, фамилия</Th>
              <Th>Telegram</Th>
              <Th>Логин</Th>
              <Th>Пароль</Th>
              <Th textAlign="end"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {operators.map((item) => <Tr key={item.id}>
              <Td>{item.id}</Td>
              <Td>{item.name}</Td>
              <Td>{item.telegram}</Td>
              <Td>{item.username}</Td>
              <Td>{item.password}</Td>
              <Td textAlign="end">
                <Tooltip label="Скопировать">
                  <IconButton aria-label="Скопировать" size="sm" mr={2}
                              onClick={() => copyHandler(item.username, item.password)}><CopyIcon/></IconButton>
                </Tooltip>
                <DeleteOperator name={item.name} id={item.id} onSuccess={() => setOperators(prevState => prevState.filter(f => f.id !== item.id))}/>
              </Td>
            </Tr>)}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Operators;
