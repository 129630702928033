import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { bindActionCreators } from "@reduxjs/toolkit";
import { allActions } from "./rootActions";

export const useActions = () => {
	const dispatch = useDispatch()

	return useMemo(() => bindActionCreators(allActions, dispatch), [dispatch])
}
