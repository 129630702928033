import axios from "../axios";
import { getModelUrl } from "../config/api.config";
import { IModel } from "../models/IModel";
import { IUserLogin } from "../models/IUser";

export const ModelService = {
  async getAll() {
    const { data } = await axios.get<IModel[]>(getModelUrl(""));

    return data;
  },
  async getForFilters() {
    const { data } = await axios.get<{label: string, value: string}[]>(getModelUrl("/filter"));

    return data;
  },
  async add(dto: IUserLogin & { smsTimeout: number, customNum: string, packCount: number, packTimeout: number, admin: string, isLockMonitoring: boolean }) {
    const { data } = await axios.post<IModel>(getModelUrl(""), dto);

    return data;
  },
  async delete(id: number) {
    const { status } = await axios.delete(getModelUrl(`/${id}`));

    return status === 200;
  },
  async edit(id: number, dto: { password: string, smsTimeout: number }) {
    const { status } = await axios.put(getModelUrl(`/${id}`), dto);

    return status === 200;
  },
  async setActive(id: number, isActive: boolean) {
    const { status } = await axios.patch(getModelUrl(`/${id}`), { isActive });

    return status === 200;
  },
  async setSmsTimeoutForAll(smsTimeout: number) {
    const { status } = await axios.patch<IModel>(getModelUrl("/sms_timeout"), {smsTimeout});

    return status === 200;
  },
  async setPackTimeoutForAll(packTimeout: number) {
    const { status } = await axios.patch<IModel>(getModelUrl("/pack_timeout"), {packTimeout});

    return status === 200;
  },
  async setPackCountForAll(packCount: number) {
    const { status } = await axios.patch<IModel>(getModelUrl("/pack_count"), {packCount});

    return status === 200;
  },
};
