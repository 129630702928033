import { FC } from "react";
import {
  Button, Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverTrigger, Switch, Text,
  useDisclosure,
} from "@chakra-ui/react";
import { IModel } from "../models/IModel";
import { useAppSelector } from "../store/hook";

type Props = {
  model: Pick<IModel, "id" | "isActive" | "isBlock" | "proxyIp" | "proxyPort" | "proxyUsername" | "proxyPassword">
  setActiveHandler: (id: number, value: boolean) => void
}

const SetActive: FC<Props> = ({ model ,setActiveHandler }) => {
  const isPaused = useAppSelector(state => state.settings.isPaused);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = () => {
    setActiveHandler(model.id, model.isActive)
    onClose()
  }
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Switch isChecked={model.isActive} isDisabled={isPaused || model.isBlock} size="sm"
                onChange={() => (model.isActive || (!model.isActive && model.proxyIp && model.proxyPort && model.proxyUsername && model.proxyPassword)) ? onSubmit() : onOpen()}/>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Text className="max-w-[300px] break-words text-wrap">Вы уверены, что хотите запустить рассылку без прокси?</Text>
        </PopoverBody>
        <PopoverFooter className="gap-4 flex items-center justify-center">
          <Button onClick={onSubmit} className="!bg-red-400 hover:!bg-red-500">Да</Button>
          <Button onClick={onClose}>Нет</Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default SetActive;
