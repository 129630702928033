import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Box, Button, Heading, Icon, IconButton, Text, Tooltip, useColorMode } from "@chakra-ui/react";
import { useActions } from "../store/useActions";
import { useAppSelector } from "../store/hook";
import AuthService from "../services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BotService } from "../services/bot.service";
import cn from "classnames";

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { setColorMode } = useColorMode();
  const { isAuth } = useAppSelector(state => state.user);
  const { isPaused, maxActiveModels, activeModels } = useAppSelector(state => state.settings);
  const { setUser, logout } = useActions();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { setSettings } = useActions();

  const logoutHandler = useCallback(() => {
    logout();
    navigate("/login");
    AuthService.removeTokens();
  }, [logout, navigate]);

  useEffect(() => {
    if (!isAuth) {
      setIsLoading(true);
      AuthService.getMe()
                 .then(setUser)
                 .catch(logoutHandler)
                 .finally(() => setIsLoading(false));

    }
  }, [setUser, isAuth, logoutHandler]);

  const getSettings = useCallback(() => {
    setIsLoading(true);
    BotService.getSettings().then(res => {
      setSettings(res);
    }).catch(() => {}).finally(() => setIsLoading(false));
  }, [setSettings]);

  useEffect(() => {
    getSettings();
    const interval = setInterval(getSettings, 60000);

    return () => {
      clearInterval(interval)
    }
  }, [getSettings]);

  const stopHandler = async () => {
    setIsLoading(true);
    await BotService.stopBot();
    getSettings();
  };

  const startHandler = async () => {
    setIsLoading(true);
    await BotService.startBot();
    getSettings();
  };


  useEffect(() => {
    setColorMode("dark");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <header className="border-b-2 p-5 flex items-center justify-between max-xl:flex-wrap max-xl:gap-4" color="red.200">
        <Heading size="md">LumensBot panel</Heading>
        <Box className="flex items-center gap-4 max-md:w-full max-xl:justify-center max-xl:mb-4">
          <Link to="/"
                className={cn("transition-colors hover:text-teal-200 hover:underline", { "text-teal-200 underline": location.pathname === "/" })}>Донаты</Link>
          <Link to="/models" className={cn("transition-colors hover:text-teal-200 hover:underline", { "text-teal-200 underline": location.pathname === "/models" })}>Модели</Link>
          <Link to="/operators" className={cn("transition-colors hover:text-teal-200 hover:underline", { "text-teal-200 underline": location.pathname === "/operators" })}>Операторы</Link>
        </Box>
        <Box className="flex items-center gap-8 max-xl:justify-between max-xl:w-full max-sm:flex-wrap">
          <Box>
            <Text>Активные: <span className={cn("font-bold", {
              "text-red-400": activeModels === 0,
              "text-green-400": activeModels > 0,
            })}>{activeModels}</span></Text>
          </Box>
          <Box>
            <Text>Максимум: <span className={cn("font-bold")}>{maxActiveModels}</span></Text>
          </Box>
          <Box>
            <Text>Статус бота: <span className={cn("font-bold", {
              "text-red-400": isPaused,
              "text-green-400": !isPaused,
            })}>{isPaused ? "Остановлен" : "Запущен"}</span></Text>
          </Box>
          <Box className="flex items-center gap-4">
            {isPaused
              ? <Tooltip label="Запустить бота">
                <IconButton aria-label="Запустить рассылку" onClick={startHandler} isLoading={isLoading}>
                  <Icon viewBox="0 0 24 24" className="!w-5 !h-5">
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM10.6219 8.41459L15.5008 11.6672C15.6846 11.7897 15.7343 12.0381 15.6117 12.2219C15.5824 12.2658 15.5447 12.3035 15.5008 12.3328L10.6219 15.5854C10.4381 15.708 10.1897 15.6583 10.0672 15.4745C10.0234 15.4088 10 15.3316 10 15.2526V8.74741C10 8.52649 10.1791 8.34741 10.4 8.34741C10.479 8.34741 10.5562 8.37078 10.6219 8.41459Z"/>
                  </Icon>
                </IconButton>
              </Tooltip>
              : <Tooltip label="Остановить бота">
                <IconButton aria-label="Остановить рассылку" onClick={stopHandler} isLoading={isLoading}>
                  <Icon viewBox="0 0 24 24" className="!w-5 !h-5">
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM9 9H11V15H9V9ZM13 9H15V15H13V9Z"/>
                  </Icon>
                </IconButton>
              </Tooltip>}
            {/*<SettingsModal settings={settings} refresh={getSettings}/>*/}
            <Tooltip label="Выйти из админ панели">
              <Button onClick={logoutHandler}>Выйти</Button>
            </Tooltip>
          </Box>
        </Box>
      </header>
      <main className="p-5">
        {children}
      </main>
    </Box>
  );
};

export default Layout;
