import {FC, useEffect, useState} from 'react';
import {
    Box,
    Table,
    TableContainer,
    Tbody,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast,
} from '@chakra-ui/react';
import {IModel} from '../models/IModel';
import {ModelService} from '../services/model.service';
import AddModel from '../components/AddModel';
import ModelRow from '../components/ModelRow';
import ApplyForAll from '../components/ApplyForAll';
import {InfoIcon} from '@chakra-ui/icons';
import {groupByAdmin} from '../utils';
import {useActions} from '../store/useActions';

const Models: FC = () => {
    const [models, setModels] = useState<IModel[]>([]);
    const [groupedModels, setGroupedModels] = useState<{ name: string, models: IModel[] }[]>([]);

    const toast = useToast();
    const {setAdmins} = useActions();

    useEffect(() => {
        ModelService.getAll()
            .then(setModels)
            .catch((err) => {
                if (err?.response?.status === 403) {
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: 'Доступ запрещен',
                    });
                } else
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: 'Произошла ошибка при загрузке данных',
                    });
            });
    }, [toast]);

    useEffect(() => {
        const interval = setInterval(() => {
            ModelService.getAll()
                .then(setModels)
                .catch(() => {
                });
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const {admins, groupedModels} = groupByAdmin(models);
        setGroupedModels(groupedModels);
        setAdmins(admins);
    }, [models]);

    const setActiveHandler = (id: number, value: boolean) => {
        ModelService.setActive(id, !value)
            .then(res => {
                if (res)
                    setModels(prevState => prevState.map(item => item.id === id ? {
                        ...item,
                        isActive: !value,
                    } : item));
                else
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: 'Произошла ошибка при обновлении данных',
                    });
            })
            .catch(() => {
                toast({
                    status: 'error',
                    title: 'Ошибка',
                    description: 'Произошла ошибка при обновлении данных',
                });
            });
    };

    return (
        <Box>
            <Box className="flex items-center justify-end gap-4">
                <ApplyForAll
                    onSuccessSmsTimeOut={(smsTimeout) => setModels(prevState => prevState.map(item => ({
                        ...item,
                        smsTimeout,
                    })))}
                    onSuccessPackTimeOut={(packTimeout) => setModels(prevState => prevState.map(item => ({
                        ...item,
                        packTimeout,
                    })))}
                    onSuccessPackCount={(packCount) => setModels(prevState => prevState.map(item => (item.messageGroup?.messages.length ?? 0) <= packCount ? {
                        ...item,
                        packCount,
                    } : item))}/>
                <AddModel onSuccess={(model) => setModels(prevState => ([...prevState, {
                    ...model,
                    followers: 0,
                    unsubscribed: '0',
                }]))}/>
            </Box>
            <TableContainer>
                {groupedModels.map(group => <div key={group.name} className="mb-12">
                    {!(groupedModels.length === 1 && group.name === 'other') &&
                        <h2 className="text-center text-3xl mb-8">{(group.name === 'other' ? 'другие' : group.name).toUpperCase()}</h2>}
                    <Table variant="striped">
                        <Thead>
                            <Tr className="[&>th]:px-1">
                                <Th>
                                    <Box className="flex items-center justify-start gap-2">
                                        <Text>Номер</Text>
                                        <Tooltip
                                            label="Значения выделенные зеленым цветом означают, что в данный момент рассылаются сообщения">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Box>
                                </Th>
                                <Th>
                                    <Box className="flex items-center justify-center gap-2">
                                        <Text>Логин</Text>
                                        <Tooltip
                                            label="Значения выделенные красным цветом означают ошибку авторизации. Неверный логин или пароль">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Box>
                                </Th>
                                <Th textAlign="center">
                                    <Box className="flex items-center justify-center gap-2">
                                        <Text>Пароль</Text>
                                        <Tooltip
                                            label="Значения выделенные красным цветом означают ошибку авторизации. Неверный логин или пароль">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Box>
                                </Th>
                                <Th textAlign="center">Свободных акк.</Th>
                                <Th textAlign="center">Разосланных акк.</Th>
                                <Th textAlign="center">Смс задержка</Th>
                                <Th textAlign="center">Размер группы</Th>
                                <Th textAlign="center">Задержка группы</Th>
                                <Th textAlign="center">Активная?</Th>
                                <Th textAlign="end"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {group.models.map(model => <ModelRow key={model.id} model={model} setActiveHandler={setActiveHandler}
                                                           onSuccessClearFollowers={() => setModels(prevState => prevState.map(f => f.id === model.id ? {
                                                               ...f,
                                                               followers: 0,
                                                               unsubscribed: '0',
                                                           } : f))}
                                                           onSuccessParse={() => setModels(prevState => prevState.map(f => f.id === model.id ? {
                                                               ...f,
                                                               isBlock: true,
                                                           } : f))}
                                                           onSuccessAddMessage={(message) => setModels(prevState => prevState.map(item => item.id === model.id ? {
                                                               ...item,
                                                               messageGroup: message,
                                                           } : item))}
                                                           onDeleteFile={(fileId) => setModels(prevState => prevState.map(item => ({
                                                               ...item,
                                                               messageGroup: item.messageGroup ? {
                                                                   ...item.messageGroup,
                                                                   files: item.messageGroup.files.filter(file => file.id !== fileId),
                                                               } : undefined,
                                                           })))}
                                                           onSuccessEdit={(data) => setModels(prevState => prevState.map(item => item.id === model.id ? {
                                                               ...item, ...data,
                                                               isInvalidProxy: item.isInvalidProxy ? !(item.proxyIp !== data.proxyIp || item.proxyPort !== data.proxyPort || item.proxyUsername !== data.proxyUsername || item.proxyPassword !== data.proxyPassword) : false,
                                                               isInvalidPassword: item.isInvalidPassword ? !(item.password !== data.password || item.username !== data.username) : false,
                                                           } : item))}
                                                           onSuccessDelete={() => setModels(prevState => prevState.filter(item => item.id !== model.id))}/>)}
                        </Tbody>
                    </Table>
                </div>)}
            </TableContainer>
        </Box>
    );
};

export default Models;
