import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    chakra,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Link,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs, Tooltip,
    useToast,
} from '@chakra-ui/react';
import {DonateType, IDonate, IDonateGrouped} from '../models/IDonate';
import {DonateService} from '../services/donate.service';
import {copyTextToClipboard, filterDonates, isGroupedResponse} from '../utils';
import moment from 'moment';
import {useDebounce} from '../hooks/useDebounce';
import Comment from '../components/Comment';
import {socket} from '../socket';
import {
    createColumnHelper, PaginationState, RowSelectionState,
} from '@tanstack/react-table';
import {DataTable} from '../components/DataTable';
import {ModelService} from '../services/model.service';
import {StarIcon} from '@chakra-ui/icons';

const columnHelper = createColumnHelper<IDonate>();
const columnGroupedHelper = createColumnHelper<IDonateGrouped>();

const allDonatesColumns = [
    columnHelper.accessor('isExternal', {
        header: "",
        cell: ({getValue}) => <chakra.span>
            {getValue() ? <Tooltip label="Парсинг со второго бота">
                <StarIcon/>
            </Tooltip> : <></>}
        </chakra.span>,
        enableSorting: false
    }),
    columnHelper.accessor('id', {
        header: ({table}) => <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />,
        cell: ({row}) => <Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
        />,
        enableSorting: false,
    }),
    columnHelper.accessor('donatedAt', {
        cell: (info) => moment(info.getValue()).format('DD.MM.YYYY, HH:mm'),
        header: 'Дата',
    }),
    columnHelper.accessor('modelUsername', {
        cell: (info) => info.getValue(),
        header: 'Модель',
    }),
    columnHelper.accessor('buyerName', {
        cell: (info) => info.getValue() === 'Account Terminated' ? '!Возможно удален с сайта' :
            <Link target="_blank"
                  href={`https://www.manyvids.com/Profile/${info.row.original.buyerId}/${info.getValue().replaceAll(' ', '-')}/Purchases/`}>{info.getValue()}</Link>,
        header: 'Аккаунт',
    }),
    columnHelper.accessor('type', {
        cell: (info) => info.getValue() === 'CUSTOM_VID' ? 'Custom Vid' : info.getValue() === 'PENDING_CUSTOM_VID' ? 'Pending Custom Vid' : 'Все',
        header: 'Тип',
    }),
    columnHelper.accessor('price', {
        cell: (info) => info.getValue().toFixed(2),
        header: 'Сумма',
    }),
];


const Home: FC = () => {
        const [isLoading, setIsLoading] = useState(false);
        const [donatesSelection, setDonatesSelection] = useState<RowSelectionState>({});
        const [allDonates, setAllDonates] = useState<{ rowCount: number, donates: IDonate[] }>({rowCount: 0, donates: []});
        const [newDonates, setNewDonates] = useState<{ rowCount: number, donates: IDonate[] }>({rowCount: 0, donates: []});
        const [groupedDonates, setGroupedDonates] = useState<{ rowCount: number, donates: IDonateGrouped[] }>({
            rowCount: 0,
            donates: [],
        });
        const [uniqModels, setUniqModels] = useState<{ value: string, label: string }[]>([]);
        const [shift, setShift] = useState('');
        const [type, setType] = useState('');
        const [model, setModel] = useState('');
        const [searchValue, setSearchValue] = useState('');

        const debounceSearch = useDebounce(searchValue, 500);
        const toast = useToast();

        const groupedDonatesColumns = useMemo(() => [
            columnGroupedHelper.accessor('isExternal', {
                header: "",
                cell: ({getValue}) => <chakra.span>
                    {getValue() ? <Tooltip label="Парсинг со второго бота">
                        <StarIcon/>
                    </Tooltip> : <></>}
                </chakra.span>,
                enableSorting: false
            }),
            columnGroupedHelper.accessor('buyerId', {
                header: ({table}) => <Checkbox
                    isChecked={table.getIsAllRowsSelected()}
                    isIndeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                />,
                cell: ({row}) => <Checkbox
                    isChecked={row.getIsSelected()}
                    isDisabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />,
                enableSorting: false,
            }),
            columnGroupedHelper.accessor('buyerName', {
                cell: (info) => info.getValue() === 'Account Terminated' ? '!Возможно удален с сайта' :
                    <Link target="_blank"
                          href={`https://www.manyvids.com/Profile/${info.row.original.buyerId}/${info.getValue().replaceAll(' ', '-')}/Purchases/`}>{info.getValue()}</Link>,
                header: 'Аккаунт',
            }),
            columnGroupedHelper.accessor('type', {
                cell: (info) => info.getValue() === 'CUSTOM_VID' ? 'Custom Vid' : info.getValue() === 'PENDING_CUSTOM_VID' ? 'Pending Custom Vid' : 'Все',
                header: 'Тип',
            }),
            columnGroupedHelper.accessor('total', {
                cell: (info) => info.getValue().toFixed(2),
                header: 'Итого',
            }),
            columnGroupedHelper.accessor('comment', {
                cell: (info) => <Comment comment={info.getValue()} buyerId={info.row.original.buyerId}/>,
                header: 'КОММЕНТАРИЙ',
            }),
        ], []);

        useEffect(() => {
            setIsLoading(true);
            ModelService.getForFilters().then(res => {
                setUniqModels(res);
            }).catch()
                .finally(fetchNewDonates);
        }, [toast]);

        const copyHandler = async () => {
            const donates = getTabDonates();
            if (donates.length === 0) return;
            const idKey = Object.keys(donates[0]).includes('total') ? 'buyerId' : 'id';
            const priceKey = Object.keys(donates[0]).includes('total') ? 'total' : 'price';
            // @ts-ignore
            const selected = donates.filter(item => Object.keys(donatesSelection).includes((item[idKey] as string).toString()));
            // @ts-ignore
            const text = selected.map(item => `${item.buyerName === 'Account Terminated' ? '!Возможно удален с сайта' : item.buyerName} (${(item[priceKey]).toFixed(2)}$) https://www.manyvids.com/Profile/${item.buyerId}/${item.buyerName.replaceAll(' ', '-')}/Purchases/`)
                .join('\n');
            await copyTextToClipboard(text);
            toast({
                status: 'info',
                title: 'Текст скопирован в буфер обмена',
            });
            setDonatesSelection({});
        };

        const getTabDonates = () => {
            switch (tabIndex) {
                case 0:
                    return allDonates.donates;
                case 1:
                    return newDonates.donates;
                case 2:
                    return groupedDonates.donates;
                default:
                    return [];
            }
        };

        const parseHandler = () => {
            setIsLoading(true);
            DonateService
                .startParse()
                .then(res => {
                    if (res)
                        toast({
                            status: 'success',
                            description: 'Парсинг успешно запущен',
                        });
                    else
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Произошла ошибка во время начала парсинга донатов',
                        });
                })
                .catch(() => {
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: 'Произошла ошибка во время начала парсинга донатов',
                    });
                })
                .finally(() => setIsLoading(false));
        };

        useEffect(() => {
            socket.on('donates', (messages: IDonate[]) => {
                const newMessages = filterDonates(messages, type as DonateType, debounceSearch, model);
                if (newMessages.length)
                    setNewDonates(prevState => ({
                        rowCount: prevState.rowCount + newMessages.length,
                        donates: [...newMessages, ...prevState.donates],
                    }));
            });

            socket.on('connect', () => console.log('ws connected'));
            socket.on('disconnect', () => console.log('ws disconnected'));
            socket.on('connect_error', (e) => console.log('ws error', e));

            return () => {
                socket.off('donates');
                socket.off('disconnect');
                socket.off('connect_error');
                socket.off('connect');
            };
        }, [type, debounceSearch, model]);

        const [tabIndex, setTabIndex] = useState(1);

        const handleTabsChange = (index: number) => {
            setNewDonates({rowCount: 0, donates: []});
            setAllDonates({rowCount: 0, donates: []});
            setGroupedDonates({rowCount: 0, donates: []});

            setTabIndex(index);
            setDonatesSelection({});
        };

        const reFetchAllDonates = useCallback((pagination: PaginationState) => {
            setIsLoading(true);
            DonateService.getAll({
                grouped: false,
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                model,
                type,
                buyer: debounceSearch,
            })
                .then((res) => {
                    if (!isGroupedResponse(res))
                        setAllDonates({rowCount: res.meta.total, donates: res.data});
                    else
                        setAllDonates({rowCount: 0, donates: []});
                })
                .catch((err) => {
                    if (err?.response?.status === 403 || err?.response?.status === 401) {
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Доступ запрещен',
                        });
                    } else
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Произошла ошибка при получении донатов',
                        });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, [toast, model, type, debounceSearch]);

        const fetchNewDonates = useCallback(() => {
            setIsLoading(true);
            DonateService.getAll({grouped: false, limit: 100, page: 1, model, type, buyer: debounceSearch, shift})
                .then((res) => {
                    if (!isGroupedResponse(res))
                        setNewDonates({rowCount: res.meta.total, donates: res.data});
                    else
                        setNewDonates({rowCount: 0, donates: []});
                })
                .catch((err) => {
                    if (err?.response?.status === 403 || err?.response?.status === 401) {
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Доступ запрещен',
                        });
                    } else
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Произошла ошибка при получении донатов',
                        });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, [toast, model, type, debounceSearch, shift]);

        const reFetchGroupedDonates = useCallback((pagination: PaginationState) => {
            setIsLoading(true);
            DonateService.getAll({
                grouped: true,
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                type,
                model,
                buyer: debounceSearch,
                shift,
            })
                .then((res) => {
                    if (isGroupedResponse(res))
                        setGroupedDonates({rowCount: res.meta.total, donates: res.data});
                    else
                        setGroupedDonates({rowCount: 0, donates: []});
                })
                .catch((err) => {
                    if (err?.response?.status === 403 || err?.response?.status === 401) {
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Доступ запрещен',
                        });
                    } else
                        toast({
                            status: 'error',
                            title: 'Ошибка',
                            description: 'Произошла ошибка при получении донатов',
                        });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, [toast, type, model, debounceSearch, shift]);

        useEffect(() => {
            if (tabIndex === 0) reFetchAllDonates({pageIndex: 0, pageSize: 20});
            if (tabIndex === 1) fetchNewDonates();
            if (tabIndex === 2) reFetchGroupedDonates({pageIndex: 0, pageSize: 20});
        }, [tabIndex, type, model, debounceSearch, shift]);

        return (
            <Box>
                <Box className="flex items-center justify-between gap-4 flex-wrap mb-4">
                    <Box
                        className="flex items-center justify-between gap-4 max-w-screen-lg max-lg:grid max-lg:grid-cols-2 max-lg:sm:flex-1 max-sm:w-full max-[600px]:grid-cols-1">
                        <FormControl className="flex items-center">
                            <FormLabel mb={0} className="max-lg:max-w-[60px] max-lg:w-full">Поиск</FormLabel>
                            <Input w={60}
                                   className="max-lg:!w-full"
                                   focusBorderColor="teal.400"
                                   value={searchValue}
                                   onChange={(e) => setSearchValue(e.target.value)}
                                   placeholder="Введите имя аккаунта"/>
                        </FormControl>
                        {tabIndex === 2 && <>
                            <FormControl className="flex items-center">
                                <FormLabel mb={0} className="max-lg:max-w-[60px] max-lg:w-full">Смена</FormLabel>
                                <Select variant="outline" onChange={(e) => setShift(e.target.value)}>
                                    <option value="">Все</option>
                                    <option value="1">00:00-06:00</option>
                                    <option value="2">06:00-12:00</option>
                                    <option value="3">12:00-18:00</option>
                                    <option value="4">18:00-00:00</option>
                                </Select>
                            </FormControl>
                        </>}
                        <FormControl className="flex items-center">
                            <FormLabel mb={0} className="max-lg:max-w-[60px] max-lg:w-full">Тип</FormLabel>
                            <Select variant="outline" onChange={(e) => setType(e.target.value)}>
                                <option value="OTHER">Все</option>
                                <option value="CUSTOM_VID">Custom Vid</option>
                                {tabIndex !== 2 && <option value="PENDING_CUSTOM_VID">Pending Custom Vid</option>}
                            </Select>
                        </FormControl>
                        <FormControl className="flex items-center">
                            <FormLabel mb={0} className="max-lg:max-w-[60px] max-lg:w-full">Модель</FormLabel>
                            <Select variant="outline" onChange={(e) => setModel(e.target.value)}>
                                <option value="">Все</option>
                                {uniqModels.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className="max-sm:flex max-sm:items-center max-sm:justify-center max-sm:w-full max-sm:mb-4">
                        {Object.keys(donatesSelection).length > 0 &&
                            <Button onClick={copyHandler} className="mr-2">Скопировать</Button>}
                        <Button onClick={parseHandler} isLoading={isLoading}>Спарсить</Button>
                    </Box>
                </Box>
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList>
                        <Tab>Все</Tab>
                        <Tab>Новые</Tab>
                        <Tab>Итого</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel p={0} pt={2}>
                            <DataTable data={allDonates.donates}
                                       columns={allDonatesColumns}
                                       rowCount={allDonates.rowCount}
                                       isLoading={isLoading}
                                       onPageChange={reFetchAllDonates}
                                       rowSelection={donatesSelection}
                                       defaultSorting={[{id: 'donatedAt', desc: true}]}
                                       setRowSelection={setDonatesSelection}/>
                        </TabPanel>
                        <TabPanel p={0} pt={2}>
                            <DataTable data={newDonates.donates}
                                       columns={allDonatesColumns}
                                       isLoading={isLoading}
                                       onPageChange={fetchNewDonates}
                                       defaultSorting={[{id: 'donatedAt', desc: true}]}
                                       rowSelection={donatesSelection}
                                       setRowSelection={setDonatesSelection}/>
                        </TabPanel>
                        <TabPanel p={0} pt={2}>
                            <DataTable data={groupedDonates.donates}
                                       columns={groupedDonatesColumns}
                                       isLoading={isLoading}
                                       rowCount={groupedDonates.rowCount}
                                       rowId="buyerId"
                                       onPageChange={reFetchGroupedDonates}
                                       rowSelection={donatesSelection}
                                       defaultSorting={[{id: 'total', desc: true}]}
                                       setRowSelection={setDonatesSelection}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        );
    }
;

export default Home;
