import axios from "../axios";
import { getBotUrl, getMessageUrl } from "../config/api.config";
import { IMessage } from "../models/IMessage";

export const MessageService = {
  async getAll() {
    const { data } = await axios.get<IMessage[]>(getMessageUrl(""));
    return data;
  },
  async add(dto: { texts: {text: string, expectedPackCount: number}[], photos: string[], videos: string[], modelId: number }) {
    const { data } = await axios.post<IMessage>(getBotUrl('/mailing'), dto);
    return data
  },
};
